<template>
<v-container class="grey lighten-5">
    <v-row no-gutters>
       <v-col>
   <div class="register">
         <h2 class="font-weight-light mt-3 mb-3">Registreer je bij Rondje Maarn</h2>
      <v-form>
         <v-text-field
            v-model="userData.displayname"
            label="Gebruikersnaam"
            required
          ></v-text-field>
          
          <v-text-field
            v-model="userData.email"
            label="Email"
            :type="'email'"
            required
          ></v-text-field>
          <v-text-field
            v-model="userData.password"
            label="Wachtwoord"
            :type="'password'"
            required
          ></v-text-field>
            <v-btn @click="registerUser()" color="primary">Maak account</v-btn>
           {{errorMessage}}
      </v-form>
   </div>
       </v-col>
    </v-row></v-container>
</template>
<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import store from '../store'
import {usersRef} from '../store/firestore'
   export default {
      name: 'Register',
      data(){
         return{
            userData: {
               displayname:'',
               email: '',
               password: '',
            },
            successMessage: '',
            errorMessage: ''
         }
      },
      methods:{
         registerUser(){
            var that = this;
            firebase.auth().createUserWithEmailAndPassword(this.userData.email, this.userData.password)
               .then(user => {
                  usersRef.add({
                           uid: user.user.uid,
                           naam: that.userData.displayname,
                           email: that.userData.email
                           })
                                       .then(function (res) {
                     console.log("Document successfully updated!", res);
                     })
                     .catch(function (error) {
                     console.error("Error writing document: ", error);
                     });
                  var userCheck = firebase.auth().currentUser;
                  userCheck.updateProfile({
                     displayName: this.userData.displayname,
                     }).then(function() {
                     // Update successful.
                        store.dispatch("fetchUser", user);
                        that.$router.push( '/')
                     }).catch(function(error) {
                     // An error happened.
                     that.errorMessage = error;
                           //that.$router.push(that.$route.query.from || '/')                        
                     });
                     
               })
               .catch(err => {
                  this.errorMessage = err.message
               });
         
         }
      }
   }
</script>